<template>
  <v-dialog
    v-model="showDeleteDialog"
    max-width="500px"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-h5">Delete {{ itemType }}</v-card-title>
      <v-card-text class="pt-5">
        Are you sure you want to delete this {{ itemType }}?</v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: ['showDeleteDialog', 'itemType'],
    data: () => {
      return {}
    },
  }
</script>
