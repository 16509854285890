<template>
  <div class="px-1">
    <v-row class="mt-0 pt-0">
      <v-col cols="12" class="mt-0 pt-0">
        <document-toolbar
          :filter-keyword.sync="filterKeyword"
          :filter-type.sync="filterType"
          :filter-client.sync="filterClient"
          :items-client="itemsClient"
        />

        <document-table
          :items="filteredFiles"
          v-on:reload-items="initializeData"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DocumentToolbar from '@/components/toolbar/DocumentToolbar.vue'
import DocumentTable from '@/components/table/DocumentTable.vue'
import FilesService from '@/services/files.service.js'
import ClientService from '@/services/client.service'

export default {
  name: 'Documents',
  metaInfo: {
    title: 'Documents'
  },
  components: {
    DocumentToolbar,
    DocumentTable
  },
  data: () => ({
    filterKeyword: '',
    filterType: null,
    filterClient: null,
    isAdmin: false,
    isSuperUser: false,
    items: [],
    itemsClient: null
  }),
  computed: {
    filteredFiles() {
      return this.items.filter((item) => {
        const cond1 = item.name.toLowerCase().indexOf(this.filterKeyword.toLowerCase()) > -1
        let cond2 = true
        let cond3 = true

        if (this.filterType) {
          if (item.reportType === null) {
            if (item.typeContent === 'Document') {
              cond2 = item.typeContent.toLowerCase().indexOf(this.filterType.toLowerCase()) > -1
            } else {
              cond2 = this.filterType > -1
            }
          } else {
            cond2 = item.reportType.toLowerCase().indexOf(this.filterType.toLowerCase()) > -1
          }
        }
        if (this.filterClient) {
          cond3 = item.client.id.toLowerCase().indexOf(this.filterClient.toLowerCase()) > -1
        }

        return cond1 && cond2 && cond3
      })
    }
  },
  watch: {
    '$store.state.initializeData.refresh': {
      handler() {
        this.refreshing()
      },
      immediate: true
    }
  },
  created() {
    this.initializeData()
  },
  mounted() {
    if (JSON.parse(localStorage.profile !== undefined)) {
      this.isSuperUser = JSON.parse(localStorage.profile).role === 'Superuser'
    }
  },
  methods: {
    async initializeData() {
      const res = await FilesService.getFiles()
      if (res) {
        this.items = res
        if (this.isSuperUser) {
          this.initializeClientData()
        }
        this.$store.dispatch('changeThemeLoadingState', false)
      }
    },
    refreshing() {
      if (this.$store.state.initializeData.refresh === true) {
        this.initializeData()
        this.$store.dispatch('initializeData/refreshData', false)
      }
    },
    async initializeClientData() {
      const resCLient = await ClientService.getClient()
      this.itemsClient = resCLient.results
    }
  }
}
</script>
