<template>
  <delete-dialog
    itemType="File"
    :showDeleteDialog.sync="showDeleteDialog"
    v-on:close="close"
  >
    <template v-slot:actions>
      <v-btn
        color="error darken-1"
        elevation="0"
        @click.native="close"
        @keyup="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary lighten-1"
        outlined
        @click.native="confirm"
      >
        Delete
      </v-btn>
    </template>
  </delete-dialog>
</template>

<script>
  import filesService from '@/services/files.service'
  import snackbarPlugin from '@/plugins/snackbar'
  import DeleteDialog from '@/components/dialog/DeleteDialog'

  export default {
    components: {
      DeleteDialog,
    },
    props: ['showDeleteDialog', 'editedItem'],
    data: () => {
      return {}
    },
    mounted () {
      document.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
          this.close()
        }
      })
    },
    methods: {
      close  () {
        this.$emit('update:show-delete-dialog', false)
      },
      confirm () {
        snackbarPlugin.showLoading('Deleting ...')
        filesService.deleteFileUploaded(this.editedItem.id).then(() => {
          snackbarPlugin.showSuccess(`File ${this.editedItem.name} deleted!`)
          this.$emit('update:show-delete-dialog', false)
          this.$emit('reload-items')
        })
      },
    },
  }
</script>
