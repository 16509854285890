import api from './api'
import { paginate } from '@/services/utils'

class ClientService {
  async postClient(data) {
    const response = await api.post('/api/v1/clients/', data)
    return response.data
  }

  async getClient() {
    const response = await api.get('/api/v1/clients/?itemsPerPage=100')
    return response.data
  }

  async getClients(page, perPage, search, filters) {
    const requestUrl = paginate('api/v1/clients/', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }

  async getClientRefs(page, perPage, search, filters) {
    const requestUrl = paginate('api/v1/clients/refs', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }

  async getByIdClient(id) {
    const response = await api.get(`/api/v1/clients/${id}`)
    return response.data
  }

  async putClient(id, data) {
    const response = await api.put(`/api/v1/clients/${id}`, data)
    return response.data
  }

  async deleteClient(id) {
    const response = await api.delete(`/api/v1/clients/${id}`)
    return response.data
  }
}

export default new ClientService()
