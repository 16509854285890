import api from './api'
import { paginate } from '@/services/utils'
import snackbarPlugin from '@/plugins/snackbar'

class PentestService {
  async postQuickPentests(data) {
    const response = await api.post('/api/v1/projects/quick-generation', data)
    return response.data
  }

  async postPentests(data) {
    const response = await api.post('/api/v1/projects/', data)
    return response.data
  }

  async getPentests(page, perPage, search, filters) {
    const requestUrl = paginate('api/v1/projects/', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }

  async getPentestRefs(page, perPage, search, filters) {
    const requestUrl = paginate('api/v1/projects/refs', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }

  async getByIdPentests(id) {
    const response = await api.get(`/api/v1/projects/${id}`)
    return response.data
  }

  async putPentest(pentestId, data) {
    const response = await api.put(`/api/v1/projects/${pentestId}`, data)
    return response.data
  }

  async putRetest(pentestId, retestId, data) {
    const response = await api.put(`/api/v1/projects/${pentestId}/retest/${retestId}`, data)
    return response.data
  }

  async postRetest(id, data) {
    const response = await api.post(`/api/v1/projects/${id}/retest`, data)
    return response.data
  }

  async deletePentest(id) {
    const response = await api.delete(`/api/v1/projects/${id}`)
    return response
  }

  async getActivity(pentestId) {
    const response = await api.get(`/api/v1/projects/${pentestId}/activities`)
    return response.data
  }

  async getByIdActivity(pentestId, activityId) {
    const response = await api.get(`/api/v1/projects/${pentestId}/activities/${activityId}`)
    return response.data
  }

  async postActivity(pentestId, data) {
    const response = await api.post(`/api/v1/projects/${pentestId}/activities`, data)
    return response.data
  }

  async deleteActivity(pentestId, activityId) {
    const response = await api.delete(`/api/v1/projects/${pentestId}/activities/${activityId}`)
    return response.data
  }

  async syncOnMonday(pentestId) {
    await api.put(`/api/v1/projects/${pentestId}/monday-sync`, {}).then((response) => {
      return response.data
    }).catch((error) => {
      if (error.request.status >= 500) {
        snackbarPlugin.showError('Synchronization failed')
      }
      return Promise.reject(error)
    })
  }
}

export default new PentestService()
