<template>
  <div>
    <base-dialog
      :show="showFileDialog"
      :form-title="isSuperUser ? 'Upload Report/Document' : 'Upload Document'"
      icon="mdi-upload"
      text-btn2="Upload"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col
            v-if="isSuperUser"
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">CLIENT <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-select
              v-model="clientId"
              :rules="[v => !!v || 'This field is required']"
              :items="itemsClient"
              item-text="name"
              placeholder="Select a client"
              item-value="id"
              :menu-props="{ bottom: true, offsetY: true }"
              outlined
              dense
              @click="getClientData"
            />
            <v-progress-linear
              class="mt-n7 mb-6"
              background-color="transparent"
              rounded
              :active="isLoadindC"
              indeterminate
            />
          </v-col>
          <v-col
            v-if="clientId"
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              PENTEST <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-select
              v-model="projectId"
              :items="itemsPentest"
              item-text="title"
              item-value="id"
              :rules="[v => !!v || 'This field is required']"
              outlined
              placeholder="Select a pentest"
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              @click="getPentestData"
            />
            <v-progress-linear
              class="mt-n7 mb-6"
              background-color="transparent"
              rounded
              :active="isLoadindP"
              indeterminate
            />
          </v-col>
          <v-col
            v-if="isSuperUser"
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">FILE TYPE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-select
              v-model="type"
              :rules="[v => !!v || 'This field is required']"
              :items="documentTypes"
              placeholder="Select type"
              item-text="text"
              item-value="value"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              FILE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-file-input
              v-model="file"
              :rules="[v => !!v || 'This field is required']"
              truncate-length="15"
              chips
              counter
              placeholder="Select file"
              outlined
              show-size
              dense
              @change="uploadFile"
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import snackbarPlugin from '@/plugins/snackbar'
import FilesService from '@/services/files.service.js'
import PentestService from '@/services/pentest.service'
import ClientService from '@/services/client.service'
import { documentTypes } from '@/data/common'
import UserService from '@/services/user.service'

export default {
  components: {
    BaseDialog
  },
  props: ['showFileDialog'],
  data() {
    return {
      file: null,
      fileId: null,
      projectId: null,
      clientId: null,
      itemsClient: null,
      itemsPentest: null,
      valid: true,
      type: null,
      isSuperUser: false,
      isUpload: false,
      documentTypes,
      isLoadindP: false,
      isLoadindC: false
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile.role === 'Superuser') {
      this.isSuperUser = true
    } else {
      this.isSuperUser = false
      this.type = "Document"
    }
    if (profile.client !== null) {
      this.clientId = profile.client.id
    }
  },
  methods: {
    async getPentestData() {
      if (!this.isSuperUser) {
        this.isLoadindP = true
        PentestService.getPentestRefs(
          1, 100, '', {}
        ).then((res) => {
          this.itemsPentest = res.results
          this.isLoadindP = false
        })
      } else {
        PentestService.getPentestRefs(
          1, 100, '', { clientID: this.clientId }
        ).then((res) => {
          this.itemsPentest = res.results
          this.isLoadindP = false
        })
      }
    },
    async getClientData() {
      this.isLoadindC = true
      await ClientService.getClient().then((res) => {
        this.itemsClient = res.results
        this.isLoadindC = false
      })
    },
    async uploadFile() {
      const res = await FilesService.postUploadFile([this.file])
      snackbarPlugin.showSuccess(`File ${this.file.name} uploaded!`)
      this.fileId = res.uuid
      if (res.res.status === 204) {
        this.isUpload = true
      }
    },
    async close(state) {
      if (!this.isUpload && state === true) {
        this.fileId = null
        this.file = null
        this.isUpload = false
      }
      if (this.isUpload && state !== true) {
        await FilesService.deleteFileUploaded(this.fileId)
        this.fileId = null
        this.file = null
        this.isUpload = false
      } else {
        this.fileId = null
        this.file = null
        this.isUpload = false
      }
      this.$emit('update:show-file-dialog', false)
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.type !== 'Document') {
          snackbarPlugin.showSuccess(`Report ${this.file.name} uploaded!`)
          await FilesService.postReport({
            type: this.type,
            projectId: this.projectId,
            fileId: this.fileId
          }).then(() => { this.$emit('reload-items') })
          await this.close(true)
        } else {
          snackbarPlugin.showSuccess(`File ${this.file.name} uploaded!`)
          await FilesService.postAddFileByPentest(this.projectId, this.fileId).then(() => { this.$emit('reload-items') })
          await this.close(true)
        }
      } else {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
