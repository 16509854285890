import api from './api'
import axios from 'axios'

const baseURL = process.env.VUE_APP_DOMAIN

class FilesService {
  async getReport() {
    const response = await api.get('/api/v1/reports/')
    return response.data
  }

  async getFiles() {
    const response = await api.get('/api/v1/files/?itemsPerPage=100')
    return response.data.results
  }

  async getFileByPentestId(id) {
    const response = await api.get(`/api/v1/projects/${id}/files`)
    return response.data.results
  }

  async getFileById(id) {
    const response = await api.get(`/api/v1/files/${id}`)
    return response.data
  }

  async getDownloadFileById(id, name, type) {
    const response = await api.get(`/api/v1/files/${id}/download-link`)
    // TODO: why is there 3 params if axios.get() accept at most 2??
    const donwloadFileS3 = await axios.get(
      `${response.data.url}`, { responseType: 'blob' },
      { headers: { 'Content-Type': 'application/json' } }
    )

    const file = new File([donwloadFileS3.data], name, {
      type: type
    })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(file)
    link.download = name
    link.click()
    URL.revokeObjectURL(link.href)

    return donwloadFileS3
  }

  async getFileDownloadFromCommunication(fileID, name, type, token) {
    const response = await axios.get(
      `${baseURL}/api/v1/communications/transfer/${fileID}/download-link`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    if (response.status !== 200) {
      throw response
    }
    // TODO: why is there 3 params if axios.get() accept at most 2??
    const donwloadFileS3 = await axios.get(
      `${response.data.url}`, { responseType: 'blob' }
    )
    if (response.status !== 200) {
      throw response
    }

    const file = new File([donwloadFileS3.data], name, { type: type })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(file)
    link.download = name
    link.click()
    URL.revokeObjectURL(link.href)

    return donwloadFileS3
  }

  async postUploadFile(files) {
    const link = await api.post(
      '/api/v1/files/upload-link', { name: files[0].name, type: files[0].type }
    )

    if (link.status === 201) {
      const formData = new FormData()

      for (const file of files) {
        formData.append('key', link.data.fields.key)
        formData.append('x-amz-algorithm', link.data.fields.xAmzAlgorithm)
        formData.append('x-amz-credential', link.data.fields.xAmzCredential)
        formData.append('x-amz-date', link.data.fields.xAmzDate)
        formData.append('policy', link.data.fields.policy)
        formData.append('x-amz-signature', link.data.fields.xAmzSignature)
        formData.append('file', file)
      }
      // ${link.data.url}
      const response = await axios.post(`${link.data.url}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return { res: response, uuid: link.data.fields.key }
    }
  }

  async postAddFileByPentest(projectId, fileId) {
    const response = await api.post(
      `api/v1/projects/${projectId}/files/${fileId}`
    ).then(() => {
      return response.data
    }).catch(() => {
      // console.log('error')
    })
  }

  async postReport(data) {
    const response = await api.post('/api/v1/reports/', data).then(() => {
      return response.data
    }).catch(() => {
      // console.log(error)
    })
  }

  async deleteFileUploaded(id) {
    const response = await api.delete(`api/v1/files/${id}`).then(() => {
      return response.data
    }).catch(() => {
      // console.log(error)
    })
  }
}

export default new FilesService()
