<template>
  <div>
    <upload-file-dialog
      :showFileDialog.sync="showFileDialog"
      v-on:reload-items="$emit('reload-items')"
    />
    <base-toolbar>
      <template v-slot:icon>
        mdi-folder-outline
      </template>
      <template v-slot:filters>
        <v-col cols="7" md="2" class="pl-0 pl-sm-3">
          <v-text-field
            hide-details
            label="Search"
            :value="filterKeyword"
            placeholder="Search"
            solo
            flat
            background-color="grey"
            dense
            single-line
            append-icon="mdi-magnify"
            class="shrink"
            @input="$emit('update:filter-keyword', $event)"
          />
        </v-col>
        <!--<v-col v-if="isSuperUser" cols="3" md="2" class="d-none d-sm-inline">
          <v-select
            label="Client"
            :value="filterClient"
            :items="itemsClient"
            item-text="name"
            item-value="id"
            solo
            flat
            background-color="grey"
            dense
            single-line
            hide-details
            class="shrink"
            @input="$emit('update:filter-client', $event)"
          />
        </v-col>
        <v-col cols="3" md="2" class="d-none d-sm-inline">
          <v-select
            label="Type"
            :value="filterType"
            :items="documentTypes"
            solo
            flat
            background-color="grey"
            dense
            single-line
            hide-details
            class="shrink"
            @input="$emit('update:filter-type', $event)"
          />
        </v-col>-->
        <div class="text-center">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-1"
                elevation="0"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-badge
                  :value="showBadge()"
                  color="primary"
                  overlap
                  dot
                >
                  <v-icon>mdi-filter-outline</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Filter</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider />

              <v-list>
                <v-list-item>
                  <v-select
                    label="Document type"
                    :value="filterType"
                    :items="documentTypes"
                    solo
                    flat
                    background-color="grey"
                    style="max-width:237px"
                    dense
                    single-line
                    clearable
                    hide-details
                    class="shrink"
                    @input="$emit('update:filter-type', $event)"
                  />
                </v-list-item>

                <v-list-item v-if="isSuperUser">
                  <v-select
                    label="Client"
                    :value="filterClient"
                    :items="itemsClient"
                    item-text="name"
                    item-value="id"
                    solo
                    flat
                    background-color="grey"
                    style="max-width:237px"
                    dense
                    single-line
                    clearable
                    hide-details
                    class="shrink"
                    @input="$emit('update:filter-client', $event)"
                  />
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <v-tooltip
          v-if="showClearFilterButton"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey"
              class="mx-2"
              fab
              small
              :elevation="0"
              @click="clearFilter"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error">mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Clear Filters</span>
        </v-tooltip>
        <v-spacer />

        <div class="ml-auto">
          <v-btn
            v-if="isReadOnly"
            color="primary"
            class="mr-0 d-none d-md-inline"
            depressed
            @click="showFileDialog = true"
          >
            <v-icon class="pr-2">mdi-file-upload-outline</v-icon><span class="d-none d-md-block">Upload</span>
          </v-btn>
          <v-menu
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-flex d-md-none"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="isReadOnly"
                link
                @click="showFileDialog = true"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-file-upload-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text text--lighten-2">Upload</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </base-toolbar>
  </div>
</template>

<script>
  import BaseToolbar from '@/components/toolbar/BaseToolbar'
  import UploadFileDialog from '@/components/dialog/documents/UploadFileDialog.vue'
  import { documentTypes } from '@/data/common'

  export default {
    components: {
      BaseToolbar,
      UploadFileDialog,
    },
    props: ['filterKeyword', 'filterType', 'filterClient', 'itemsClient'],
    data: () => {
      return {
        documentTypes,
        isSuperUser: false,
        showFileDialog: false,
        menu: false,
        isReadOnly: true,
      }
    },
    computed: {
      showClearFilterButton () {
        if (this.filterKeyword || this.filterType || this.filterClient) {
          return true
        }
        return false
      },
    },
    mounted () {
      if (JSON.parse(localStorage.profile !== undefined)) {
        if (JSON.parse(localStorage.profile).role === 'Superuser') {
          this.isSuperUser = true
        } else {
          this.isSuperUser = false
        }

        if (JSON.parse(localStorage.profile).role === 'Client User') {
          this.isReadOnly = false
        } else {
          this.isReadOnly = true
        }
      }
    },
    methods: {
      showBadge () {
        if (this.filterStatus || this.filterType || this.filterClient || this.filterAsset) {
          return true
        } else {
          return ''
        }
      },
      clearFilter () {
        this.$emit('update:filter-keyword', '')
        this.$emit('update:filter-type', null)
        this.$emit('update:filter-client', null)
      },
    },
  }
</script>
