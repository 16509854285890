<template>
  <div>
    <delete-file-dialog
      :editedItem="editedItem"
      :showDeleteDialog.sync="showDeleteDialog"
      v-on:reload-items="$emit('reload-items')"
    />
    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="localItems"
        :items-per-page="5"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar
            color="grey"
            class="my-5 d-none d-sm-flex"
            :class="[item.isDeleted ? 'error' : '']"
          >
            <v-icon
              medium
              class="my-2"
            >
              {{ setIconType(item.name).icon }}
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.name="{ item }">
          <v-list-item-content
            class="cursor-pointer"
            @click="downloadReport(item)"
          >
            <v-list-item-title
              class="font-weight-bold resizeTextRST"
              :class="[item.isDeleted ? 'error--text' : '']"
            >
              <span class="primary--text text--lighten-2">{{ item.name }}</span>
              <span
                v-if="item.isDeleted"
                class="error--text"
              >
                [ Deleted ]
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ new Date(item.createdAt.slice(0, 16) + '+0000').toLocaleDateString('fr-CA').replace(/\//g, '-') }}
          </div>
        </template>

        <template v-slot:item.uploadBy="{ item }">
          <h6 class="">
            {{ item.uploadBy }}
          </h6>
        </template>

        <template v-slot:item.reportType="{ item }">
          <v-chip
            v-if="item.reportType && item.reportType in docTypes"
            class="text-capitalize"
            color="success"
            outlined
          >
            {{ docTypes[item.reportType] }}
          </v-chip>
          <v-chip
            v-if="!item.reportType || !(item.reportType in docTypes)"
            class="text-capitalize"
            color="success"
            outlined
          >
            {{ docTypes.document }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <span class="d-none d-lg-flex">
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  depressed
                  class=""
                  icon
                  large
                  :disabled="item.lastReportFile === null"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadReport(item)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>

            <v-tooltip v-if="isHavePermDel(item)" bottom>
              <template v-if="item.typeContent !== 'Report' || item.typeContent === 'Report' && isSuperUser" v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isAdmin"
                  color="danger"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.isDeleted"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <v-spacer />
          </span>
          <v-menu
            bottom
            left
            class="d-md-flex d-lg-none"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-md-flex d-lg-none ml-14"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item
                link
                :class="item.lastReportFile === null ? 'gray--text' : 'primary--text text--lighten-2'"
                :disabled="item.lastReportFile === null"
                @click="downloadReport(item)"
              >
                <v-icon class="mr-2" :class="item.lastReportFile === null ? 'gray--text' : 'primary--text text--lighten-2'">mdi-download</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Download</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isHavePermDel(item)"
                link
                :class="item.isDeleted ? 'gray--text' : 'error--text'"
                :disabled="item.isDeleted"
                @click="deleteItem(item)"
              >
                <span v-if="item.typeContent !== 'Report' || item.typeContent === 'Report' && isSuperUser">
                  <v-icon class="mr-2" :class="item.isDeleted ? 'gray--text' : 'error--text'">mdi-delete-outline</v-icon>
                </span>
                <v-list-item-content v-if="item.typeContent !== 'Report' || item.typeContent === 'Report' && isSuperUser">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import FilesService from '@/services/files.service.js'
import DeleteFileDialog from '@/components/dialog/documents/DeleteFileDialog.vue'
import { documentTypes, docTypes, selectIconFileType } from '@/data/common'
import UserService from '@/services/user.service'

export default {
  components: {
    DeleteFileDialog
  },
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: () => ({
    showDeleteDialog: false,
    showEditDialog: false,
    editedItem: {},
    editedIndex: -1,
    isAdmin: false,
    isSuperUser: false,
    documentTypes,
    docTypes,
    selectIconFileType,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'icon',
        width: '20px',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'File name',
        value: 'name',
        class: 'text-left font-weight-medium subtitle-1'
      },
  
      {
        text: '',
        value: '',
        sortable: false,
        class: 'font-weight-bold'
      },
      {
        text: 'Type',
        value: 'reportType',
        align: 'center',
        width: '180px',

        class: 'text-left font-weight-medium subtitle-1'
      },
      {
        text: 'Created on',
        width: '160px',
        value: 'createdAt',
        align: 'left',
        class: 'text-left font-weight-medium subtitle-1'
      },    
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '140px',
        class: 'text-center font-weight-medium subtitle-1'
      }
    ]
  }),
  computed: {
    localItems() {
      return this.items
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      if (profile.role === 'Superuser') {
        this.isSuperUser = true
        this.headers[2].text = 'Client'
        this.headers[2].value = 'client.name'
        this.headers[2].class = 'font-weight-bold'
      } else {
        this.isSuperUser = false
      }

      this.isAdmin = profile.role !== 'Client User'
    }
  },
  methods: {
    setIconType(filename) {
      let fileType = "";
      const extensions = filename.split(".")
      const lastExtension = extensions[extensions.length - 1]
      if (lastExtension.length >= 2 && lastExtension.length <= 8) {
        fileType = lastExtension;
      } else {
        return { icon: "mdi-file-outline", color: "" }
      }

      const matchingIcon = this.selectIconFileType.find(el => el.text === fileType)

      if (matchingIcon) {
        return {
          icon: matchingIcon.value,
          color: matchingIcon.color
        }
      } else {
        return { icon: "mdi-file-outline", color: "" }
      }
    },
    isHavePermDel(item) {
      if (item.uploadBy.id === JSON.parse(localStorage.profile).id) {
        return true
      } else {
        return this.isSuperUser
      }
    },
    deleteItem(item) {
      this.editedIndex = this.localItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDeleteDialog = true
    },
    async downloadReport(item) {
      await FilesService.getDownloadFileById(item.id, item.name, item.type)

      this.$swal({
        text: 'Download has just started!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok, got it!',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-primary'
        }
      }).then(() => {
        this.dialog = false
      })
    }
  }
}
</script>

<style lang="scss">
.resizeTextRST{
  word-break: break-word;
  inline-size: 210px;
}
</style>
